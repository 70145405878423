//Idea of this page is to construct a report for our customers
import DebtChart from "../../components/chart/Chart";
import classes from "../Raport.module.css";
import "../../components/estatelist/List.module.css";
import hox from "../../assets/HOX.png";
import question from "../../assets/question-mark.png";
import PropertyType from "../../components/propertytype/PropertyType";
import CheckNaN from "../../components/checknan/CheckNaN";
import CheckTotal from "../../components/checknan/CheckTotal";
import title from "../../assets/title.png";

function A4Raport({ data, defaults }) {
  function trafficLight(value1, value2) {
    let theValue = value2 < 0 ? value1 : Math.min(value1, value2);
    if (theValue <= 5) {
      return 0;
    } else if (theValue <= 15) {
      return 1;
    } else {
      return 2;
    }
  }
  return (
    <div className="report" style={{ width: "1400px" }}>
      <div className={classes.padding}>
        <br />
        <br />
        <br />
        <br />
        <img src={title} style={{ width: "100%" }} alt="kuva" />
        <br />
        <br />
        <br />
        <h1>{data.name}</h1>
        <div style={{ justifyContent: "space-between", display: "flex" }}>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <nobr style={{ display: "flex", flexDirection: "row" }}>
              <h5 style={{ paddingRight: "1%" }}>{data.address},</h5>
              <h5 style={{ paddingRight: "1%" }}>{data.postNumber}</h5>
              <h5 style={{ paddingRight: "1%" }}>{data.city},</h5>
              <PropertyType propertyType={data.propertyType} />
              <h5 style={{ paddingRight: "1%" }}>{data.floors} krs,</h5>
              <h5 style={{ paddingRight: "1%" }}>
                Rakennusvuosi: {data.constructed}
              </h5>
            </nobr>
          </div>
          <p style={{ fontWeight: "bold" }}>
            www.korjausvelkalaskuri.fi
            <br />
            tilaukset@korjausvelkalaskuri.fi
          </p>
        </div>

        <br />
        <div style={{ display: "grid", width: "100%", alignItems: "start" }}>
          <h4 style={{ margin: 0 }}>Yhteenveto talon tekniikan tilasta</h4>
          <div style={{ display: "flex", gap: "0.5%", alignItems: "center" }}>
            <img
              src={hox}
              alt="prop"
              style={{ height: "20px", paddingLeft: "0.5%", opacity: "0.8" }}
            />
            <p style={{ margin: "0" }}>
              Seuraavia arvoja käytettiin tehtyjen korjausvelka laskelmien
              pohjana. Laskelmat ovat riippuvaisia näiden tietojen
              oikeellisuudesta.
            </p>
            <br />
            <br />
          </div>
        </div>
        <table
          className={classes.local}
          style={{
            backgroundColor: "white",
            boxShadow: "1px 3px 13px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "20%" }}></th>
              <th style={{ width: "10%" }}>Remontoitava määrä</th>
              <th style={{ width: "10%" }}>
                Rakennettu / remontoitu viimeksi v
              </th>
              <th style={{ width: "10%" }}>Tyypillinen kestoikä v</th>
              <th style={{ width: "10%" }}>Arvioitu korjausvuosi v</th>
              <th style={{ width: "10%" }}>
                Korjauksen hinta € / m<sup>2</sup>
              </th>
              <th style={{ width: "10%" }}>
                Taloyhtiön korjausvelka € / m<sup>2</sup>
              </th>
              <th style={{ width: "10%" }}>
                {data.apartmentArea} m<sup>2</sup> Asunnon korjausvelka €
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className={classes.row}>
                Viemäriputket
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.sewer_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.sewer_renovated +
                          defaults.Sewerspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.sewer_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.sewer_renovated +
                              defaults.Sewerspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="sewer-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.sewer_area)} m²</td>
              <td>{data.sewer_renovated}</td>
              <td>{defaults.Sewerspan}</td>
              <td>{data.sewer_actualPlanned}</td>
              <td>{Math.round(data.sewer_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.sewer_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.sewer_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Käyttövesiputket
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.plumbing_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.plumbing_renovated +
                          defaults.Plumbingspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.plumbing_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.plumbing_renovated +
                              defaults.Plumbingspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="plumbing-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.plumbing_area)} m²</td>
              <td>{data.plumbing_renovated}</td>
              <td>{defaults.Plumbingspan}</td>
              <td>{data.plumbing_actualPlanned}</td>
              <td>{Math.round(data.plumbing_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.plumbing_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.plumbing_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Märkätilat
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.bathroom_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.bathroom_renovated +
                          defaults.Bathroomspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.bathroom_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.bathroom_renovated +
                              defaults.Bathroomspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="bathroom-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.bathrooms)} kpl</td>
              <td>{data.bathroom_renovated}</td>
              <td>{defaults.Bathroomspan}</td>
              <td>{data.bathroom_actualPlanned}</td>
              <td>{Math.round(data.bathroom_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.bathroom_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.bathroom_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Lämmitysputkisto
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.heating_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.heating_renovated +
                          defaults.Heatingspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.heating_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.heating_renovated +
                              defaults.Heatingspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="heating-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.heating_area)} m²</td>
              <td>{data.heating_renovated}</td>
              <td>{defaults.Heatingspan}</td>
              <td>{data.heating_actualPlanned}</td>
              <td>{Math.round(data.heating_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.heating_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.heating_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Sähkö- ja telejärjestelmä
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.elec_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.elec_renovated +
                          defaults.Elecspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.elec_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.elec_renovated +
                              defaults.Elecspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="elec-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.elec_area)} m²</td>
              <td>{data.elec_renovated}</td>
              <td>{defaults.Elecspan}</td>
              <td>{data.elec_actualPlanned}</td>
              <td>{Math.round(data.elec_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.elec_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.elec_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Ilmanvaihto, hormit
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.vent_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.vent_renovated +
                          defaults.Ventspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.vent_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.vent_renovated +
                              defaults.Ventspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="vent-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.vent_area)} m²</td>
              <td>{data.vent_renovated}</td>
              <td>{defaults.Ventspan}</td>
              <td>{data.vent_actualPlanned}</td>
              <td>{Math.round(data.vent_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.vent_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.vent_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Julkisivut
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.front_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.front_renovated +
                          defaults.Frontspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.front_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.front_renovated +
                              defaults.Frontspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="front-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.front_area)} m²</td>
              <td>{data.front_renovated}</td>
              <td>{defaults.Frontspan}</td>
              <td>{data.front_actualPlanned}</td>
              <td>{Math.round(data.front_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.front_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.front_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Ikkunat ja parvekkeet
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.windows_and_balconies_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.windows_and_balconies_renovated +
                          defaults.Windowsandbalconiesspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.windows_and_balconies_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.windows_and_balconies_renovated +
                              defaults.Windowsandbalconiesspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="front-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{/* {Math.round(data.windows_and_balconies_area)} m² */}</td>
              <td>{data.windows_and_balconies_renovated}</td>
              <td>{defaults.Windowsandbalconiesspan}</td>
              <td>{data.windows_and_balconies_actualPlanned}</td>
              <td>{Math.round(data.windows_and_balconies_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.windows_and_balconies_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.windows_and_balconies_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Salaojat ja sokkeli
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.yard_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.yard_renovated +
                          defaults.Yardspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.yard_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.yard_renovated +
                              defaults.Yardspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="yard-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{/* Math.round(data.yard_area) */}</td>
              <td>{data.yard_renovated}</td>
              <td>{defaults.Yardspan}</td>
              <td>{data.yard_actualPlanned}</td>
              <td>{Math.round(data.yard_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.yard_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.yard_maintenance}</td>
            </tr>
            {data.yard_cover_maintenanceDebtPerSqm !==
              "0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0" &&
            data.yard_cover_maintenanceDebtPerSqm !== "" ? (
              <tr>
                <th className={classes.row}>
                  Pihakansi
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <span
                      style={
                        trafficLight(
                          Number(data.yard_cover_actualPlanned) -
                            new Date(Date.now()).getFullYear(),
                          data.yard_cover_renovated +
                            defaults.Yardcoverspan -
                            new Date(Date.now()).getFullYear()
                        ) === 0
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "red",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : trafficLight(
                              Number(data.yard_cover_actualPlanned) -
                                new Date(Date.now()).getFullYear(),
                              data.yard_cover_renovated +
                                defaults.Yardcoverspan -
                                new Date(Date.now()).getFullYear()
                            ) === 1
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "yellow",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "green",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                      }
                    />
                    <img
                      id="yardCase-img"
                      src={question}
                      alt="prop"
                      style={{
                        height: "20px",
                        paddingLeft: "0.5%",
                        opacity: "0.8",
                      }}
                    />
                  </div>
                </th>
                <td>{/* {Math.round(data.yard_cover_area)} */}</td>
                <td>{data.yard_cover_renovated}</td>
                <td>{defaults.Yardspan}</td>
                <td>{data.yard_cover_actualPlanned}</td>
                <td>{Math.round(data.yard_cover_rRPrice)}</td>
                <td>
                  <CheckNaN
                    maintenance={data.yard_cover_maintenance}
                    apartment={data.apartmentArea}
                  />
                </td>
                <td>{data.yard_cover_maintenance}</td>
              </tr>
            ) : (
              <></>
            )}
            <tr>
              <th className={classes.row}>
                Katto
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <span
                    style={
                      trafficLight(
                        Number(data.roof_actualPlanned) -
                          new Date(Date.now()).getFullYear(),
                        data.roof_renovated +
                          defaults.Roofspan -
                          new Date(Date.now()).getFullYear()
                      ) === 0
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "red",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : trafficLight(
                            Number(data.roof_actualPlanned) -
                              new Date(Date.now()).getFullYear(),
                            data.roof_renovated +
                              defaults.Roofspan -
                              new Date(Date.now()).getFullYear()
                          ) === 1
                        ? {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "yellow",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                        : {
                            width: "19px",
                            height: "19px",
                            backgroundColor: "green",
                            borderRadius: "50%",
                            border: "black",
                            borderStyle: "solid",
                            borderWidth: "1px",
                          }
                    }
                  />
                  <img
                    id="roof-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>
                {Math.round(
                  ((data.residentialArea + data.commercialArea) / data.floors) *
                    1.2
                )}{" "}
                m²
              </td>
              <td>{data.roof_renovated}</td>
              <td>{defaults.Roofspan}</td>
              <td>{data.roof_actualPlanned}</td>
              <td>{Math.round(data.roof_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.roof_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.roof_maintenance}</td>
            </tr>
            <tr>
              <th className={classes.row}>
                Hissi
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  {data.elevators !== 0 && (
                    <span
                      style={
                        trafficLight(
                          Number(data.elevators_actualPlanned) -
                            new Date(Date.now()).getFullYear(),
                          data.elevators_renovated +
                            defaults.Elevatorspan -
                            new Date(Date.now()).getFullYear()
                        ) === 0
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "red",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : trafficLight(
                              Number(data.elevators_actualPlanned) -
                                new Date(Date.now()).getFullYear(),
                              data.elevators_renovated +
                                defaults.Elevatorspan -
                                new Date(Date.now()).getFullYear()
                            ) === 1
                          ? {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "yellow",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                          : {
                              width: "19px",
                              height: "19px",
                              backgroundColor: "green",
                              borderRadius: "50%",
                              border: "black",
                              borderStyle: "solid",
                              borderWidth: "1px",
                            }
                      }
                    />
                  )}
                  <img
                    id="elevators-img"
                    src={question}
                    alt="prop"
                    style={{
                      height: "20px",
                      paddingLeft: "0.5%",
                      opacity: "0.8",
                    }}
                  />
                </div>
              </th>
              <td>{Math.round(data.elevators)} kpl</td>
              <td>{data.elevators_renovated}</td>
              <td>{defaults.Elevatorspan}</td>
              <td>{data.elevators_actualPlanned}</td>
              <td>{Math.round(data.elevators_rRPrice)}</td>
              <td>
                <CheckNaN
                  maintenance={data.elevators_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td>{data.elevators_maintenance}</td>
            </tr>
            <tr>
              <th style={{ border: "none" }}>Yhteensä</th>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ border: "none" }}></td>
              <td style={{ fontWeight: "bold", border: "none" }}>
                {Math.round(
                  data.sewer_rRPrice +
                    data.plumbing_rRPrice +
                    data.bathroom_rRPrice +
                    data.heating_rRPrice +
                    data.elec_rRPrice +
                    data.vent_rRPrice +
                    data.front_rRPrice +
                    data.windows_and_balconies_rRPrice +
                    data.yard_rRPrice +
                    data.yard_cover_rRPrice +
                    data.roof_rRPrice +
                    data.elevators_rRPrice
                )}
              </td>

              <td style={{ fontWeight: "bold", border: "none" }}>
                <CheckTotal
                  total={data.total_maintenance}
                  apartment={data.apartmentArea}
                />
              </td>
              <td style={{ fontWeight: "bold", border: "none" }}>
                {data.total_maintenance}
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "20px",
            marginTop: "10px",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "red",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää 5 vuotta tai alle
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "yellow",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää 6-15 vuotta
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span
              style={{
                width: "19px",
                height: "19px",
                backgroundColor: "green",
                borderRadius: "50%",
                border: "black",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            />
            Teknistä käyttöikää yli 15 vuotta
          </div>
        </div>
        <h3 style={{ paddingTop: "2.5%" }}>
          Taloyhtiön korjausvelan määrä €/m<sup>2</sup> sekä arvioitu velkaosuus remonteista {data.apartmentArea}m<sup>2</sup> asunnolle
        </h3>
        <DebtChart />
      </div>
      <div></div>
    </div>
  );
}

export default A4Raport;
