import classes from "../Raport.module.css";
import "../../components/estatelist/List.module.css";
import SeparatedCompensationChart from "../../components/chart/SeparatedCompensationChart";
import firstImage from "../../assets/firstImage.png";
import secondImage from "../../assets/secondImage.png";

function A4Raport2({ data }) {
  return (
    <div className="report2" style={{ width: "1400px" }}>
      <div className={classes.padding}>
        <br />
        <br />
        <img src={firstImage} style={{ width: "100%" }} alt="kuva" />
        <br />
        <h3 style={{ paddingTop: "2.5%" }}>
          {data.apartmentArea}m<sup>2</sup> asunnon rahoitusvastikkeen arvioitu
          lisäys (korkomaksut ja lyhennys eriteltynä €/kk)
        </h3>
        <p>
          {data.loanperiod} vuoden laina-ajalla ja {data.rate} % korolla
        </p>
        <SeparatedCompensationChart />
        {/* <p>
          <p style={{ fontStyle: "italic" }}>
            Palveluntarjoaja ei vastaa näiden lähteiden eikä raportissa näiden
            lähteiden esitettyjen tietojen oikeudellisuudesta eikä mahdollisista
            tulkinnoista tehtyjen korjausten sisällössä tai ajankohdissa.
            Palveluntarjoaja ei myöskään vastaa vahingoista, joita tämän
            raportin käytöstä mahdollisesti aiheutuu. Tulokset ovat
            suuntaa-antavia. Rakennusalan kiihtynyt hintojen nousu nostaa näinä
            aikoina kustannuksia.
          </p>
        </p> */}
        <br />
        <br />
        <img src={secondImage} style={{ width: "100%" }} alt="kuva" />
      </div>
      <div></div>
    </div>
  );
}

export default A4Raport2;
